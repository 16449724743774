var 
$body = jQuery('body'),
app = {
  init: function(){
    
    // Remove PDF content on mobile
    
    if(innerWidth < 1091){
      jQuery('.touch .single-project #print, .touch .single-project #related-content .nav-tabs li:first-child').remove();
    
      jQuery('.touch #related-content .nav-tabs li:first h4').click();

    }
    
    
    // top nav non-mobile
    
    if(!jQuery('body').hasClass('.no-touch') && innerWidth > 991 ){
      jQuery('#menu-main-menu > li.dropdown > a').on('click', function(e){
        var link = jQuery(this).parent().find('.dropdown-menu > li:first-child a').attr('href');
        location.href=link;
      }); 
    }
    
    // IE 10
    if(navigator.appVersion.indexOf("MSIE 10") !== -1){ 
        $body.addClass('ie10'); }

    var home = $body.hasClass('home');
    
    if( !home ){
      // mobile swipe carousels  
      jQuery('.carousel.h-100')
        //.height(innerHeight*.66)
        .height(innerHeight - 80)
        .bcSwipe({ threshold: 50 })
        .find('.slider-bg-img').hide();
      
      
      
      jQuery('#project-header, #project-content  .project-intro')
        //.height(innerHeight*.66)
        .height((innerHeight*.5)-40)
        .bcSwipe({ threshold: 50 })
        .find('.slider-bg-img').hide();
      
      var h = innerHeight - 80; 
      //if(innerWidth > 768) h = (innerHeight*.65) - 60;
      
      jQuery('#featured-header')
        //.height(innerHeight*.66)
        .height(h)
        .bcSwipe({ threshold: 50 })
        .find('.slider-bg-img').hide();
        
    }else{
      
      // set slideri height
      jQuery('#slider')
        .height(innerHeight-80)
        .bcSwipe({ threshold: 50 })
        .find('.slider-bg-img').hide();
      
      var maxHeight = -1;
      
      jQuery('.featured-copy').each(function() {
          maxHeight = maxHeight > jQuery(this).height() ? maxHeight : jQuery(this).height();
      });
      
      jQuery('.featured-copy').height(maxHeight);
    }
    
    // Window scrolling
    
    window.addEventListener('scroll', function(){
      if( window.scrollY > 100 && innerWidth > 991) $body.addClass('scroll');
      else $body.removeClass('scroll');
    });
    
    
    // NEWS EVENTS
    
    jQuery('#news-events-header small, .view-all').on('click', function(e){
      e.preventDefault(); e.stopPropagation();
      jQuery('html, body').animate({
        scrollTop: innerHeight - 40
      }, 1000);
    });
    
    
    // PROJECT PAGES (PROPORTIONS)
    
    if($body.hasClass('single-project')){
      var 
      resizeTimer,
      adjustProjectProportions = function(){

        clearTimeout(resizeTimer);
        
        resizeTimer = setTimeout(function() {
          
          
          if(innerWidth > 993){
            jQuery('#project-slider')
              .height( jQuery('#project-details').height())
              .bcSwipe({ threshold: 50 });
              
            var 
            $details = jQuery('#project-details-copy > .container-md'),
            $slider = jQuery('#project-slider');
            
            var xyRatio = $slider.height() / $slider.width();
            if(xyRatio  < .55){
              //console.log('horiz', xyRatio);
              $slider.height( $slider.width() * .66);
              $details.css({
                "margin-top": ($slider.height() - $details.height()) /3 + "px"
              });  
            }else{
              //console.log('not horiz??', xyRatio);
              //$slider.height( jQuery('#project-details').height());
              //$details.css({
              //  "margin-top": ($slider.height() - $details.height()) /3 + "px"
              //});
              $details.css('margin-top',0);
              $slider.height($details.parent().height());
            }
            jQuery('#project-slider').height( jQuery('#project-details').height());
            
          }else{
            $details.parent().css('height', 'auto');
            $details.css({
              "margin-top": "25px"
            });
          }
        }, 250);
      }
      window.onresize = adjustProjectProportions;
      adjustProjectProportions();
    }
    
    
    
    
    // Search bar
    
    // show/hide
    jQuery('#menu-main-menu li.search a').text('').addClass('icon-search')
    .on('click', function(e){
      e.preventDefault();
      e.stopPropagation();
      jQuery('#search').toggleClass('active');
      jQuery('#input-search').eq(0).focus();
    });
    
    jQuery('#icon-close').on('click', function(e){
      e.preventDefault();
      e.stopPropagation();
      jQuery('#search').toggleClass('active');
      jQuery('#input-search').eq(0).blur();
    });
    
    // placeholder copy
    jQuery('.search-field').focus(function(){
      jQuery(this)
        .data('placeholder', jQuery(this).attr('placeholder'))
        .attr('placeholder','');
    }).blur(function(){
      jQuery(this).attr('placeholder', jQuery(this).data('placeholder'));
    });
    

    // Top level nav links
    
    
    
    // cache
    var 
    $res = jQuery('#ajax-results'),
    $stitle = jQuery('#results-title');
    
    // AJAX search
    jQuery('#input-search').on('keyup', function(e){
      
      // disable on devices
      if(innerWidth<992) return false;
      
      var q = jQuery(this).val();
      if(q.length < 2) return false;
      var 
      search = jQuery.ajax({
        url: '/?s='+q+'&ajax',
        dataType: 'json',
        method: 'GET',
        error: function(e,a){ console.log('error');console.log(arguments); },
        success: function(d){
          $res.empty();
          $stitle.empty();
          
          if(d.length > 1) $stitle.html('Results for "'+q+'" <br/>');
          else $stitle.html('No results <br/>'); 
            
          for(var i=0;i<d.length;i++){
            $res.append('<li><a href="/?p='+d[i].ID+'">'
            + '<small>'+d[i].post_type+':</small>' 
            + d[i].post_title + '</a></li>'); 
          }
        }  
      });
       
    });
    
  }
};


/*! Bootstrap Carousel Swipe   */
    !function(t){t.fn.bcSwipe=function(e){var n={threshold:50};return e&&t.extend(n,e),this.each(function(){function e(t){1==t.touches.length&&(u=t.touches[0].pageX,c=!0,this.addEventListener("touchmove",o,!1))}function o(e){if(c){var o=e.touches[0].pageX,i=u-o;Math.abs(i)>=n.threshold&&(h(),t(this).carousel(i>0?"next":"prev"))}}function h(){this.removeEventListener("touchmove",o),u=null,c=!1}var u,c=!1;"ontouchstart"in document.documentElement&&this.addEventListener("touchstart",e,!1)}),this}}(jQuery);
    

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
      app.init();

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


